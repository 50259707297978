import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const CustomerReview = ({ selectedCard }) => {
  // Define the data for each card
  const [ratingsData, setRatingsData] = useState({
    0: [], // Data for card 0 (default)
    1: [], // Data for card 1 (fallback count)
    2: [], // Data for card 2 (weeks)
  });

  // Define the x-axis categories for each card
  const [xAxisCategories, setXAxisCategories] = useState({
    0: ["1", "2", "3", "4", "5", "No Rating"], // Categories for card 0 (default)
    1: [], // Categories for card 1 (fallback occurrences)
    2: ["Week 1", "Week 2", "Week 3", "Week 4"], // Categories for card 2 (weeks)
  });

  // Define the tooltips for each card
  const tooltips = {
    0: (val) => `${val} reviews`,
    1: (val) => `${val} fallbacks`,
    2: (val) => `${val} chats`,
  };

  useEffect(() => {
    // Simulating API call or data fetching here
    // Replace with actual data fetching logic
    fetch('http://localhost:8000/dashboard/')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const { ratings_count, fallback_occurrences } = data;

        // Update ratingsData state properly
        setRatingsData(prevState => ({
          ...prevState,
          0: Object.values(ratings_count), // Use ratings_count for card 0
          1: fallback_occurrences.map(monthData => Object.values(monthData)[0]), // Use fallback occurrences for card 1
        }));

        // Update x-axis categories for card 1
        setXAxisCategories(prevState => ({
          ...prevState,
          1: fallback_occurrences.map(monthData => Object.keys(monthData)[0]),
        }));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array ensures useEffect runs once on component mount

  // Define the chart options
  
  // Define the chart options
// Define the chart options
const data = {
  series: [
    {
      name: "Data Count",
      data: ratingsData[selectedCard], // Use data based on selectedCard
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: "auto",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "flat",
        dataLabels: {
          position: "top", // Position labels at the end of bars
        },
      },
    },
    dataLabels: {
      enabled: true, // Enable data labels
      formatter: function (val) {
        return val.toFixed(0); // Format label to fixed decimal places
      },
      offsetY: -20, // Adjust vertical position if necessary
      style: {
        colors: ["#000000"], // Change text color to black (#000000)
        fontSize: "12px", // Adjust font size if needed
      },
    },
    xaxis: {
      categories: xAxisCategories[selectedCard], // Use categories based on selectedCard
      labels: {
        rotate: 0,
      },
    },
    yaxis: {
      title: {
        text: "Count",
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    tooltip: {
      y: {
        formatter: tooltips[selectedCard], // Use tooltip formatter based on selectedCard
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false,
    },
  },
};


  return (
    <div className="CustomerReview">
      <Chart options={data.options} series={data.series} type="bar" height={250} />
    </div>
  );
};

export default CustomerReview;
