import React from "react";
import "./Header.css";


const Header = ({ selected }) => {
  let heading;

  switch (selected) {
    case 0:
      heading = "Dashboard ";
      break;
    case 1:
      heading = "Conversations";
      break;
    case 2:
      heading = "User details";
      break;
    // Add more cases as needed
    default:
      heading = "Dashboard";
      break;
  }

  return (
    
  
    <div className="Header" style={{ gridRow: 1}}>
      <h1 className="heading" >{heading}</h1>
    </div>
    
  );
};

export default Header;
