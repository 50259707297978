import React, { useState } from 'react';

import Login from './Pages/Login/Login';
import Dashboard from './Layout/Layout'
function App() {
const [isLoggedIn, setIsLoggedIn] = useState(false);
const handleLogin = () => {
    // Implement your authentication logic here
    setIsLoggedIn(true);
  };

  return (<>
    {!isLoggedIn ? (
      <Login onLogin={handleLogin} />
    ) : (
     <Dashboard/>
    )}
    </>
  );
}

export default App;
