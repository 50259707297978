import React from "react";
import Cards from "../Pages/Dashboard/Cards/Cards";
import Table from "../Pages/Dashboard/DashTable/DashTable";
import "./Routes.css";
import Employee from '../Pages/Userdetails/Userdetails';
import Chats from"../Pages/Conversation/Conversation";


const MainDash = ({ selected, onCardSelect }) => {
  let content;
  let heading;

  switch (selected) {
    case 0:
      heading = "Dashboard ";
      content = (
        <div className="dash">
          <Cards onCardSelect={onCardSelect} />
          <Table />
        </div>
      );
      break;
    case 1:
      heading = "Chats";
      content = (<Chats />);
      break;
    case 2:
      heading = "Employee Details";
      content = (<Employee />);
      break;
    default:
      heading = "Dashboard";
      content = <div></div>;
      break;
  }

  return (
    <div className="MainDash" style={{ gridRow: 2 }}>
      {content}
    </div>
  );
};

export default MainDash;
