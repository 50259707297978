import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'material-icons/iconfont/material-icons.css';
import './Userdetails.css';

const EmployeeDashboard = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [modalType, setModalType] = useState('');
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const perPage = 5; // Adjust this based on your pagination needs

  useEffect(() => {
    fetchEmployees();
  }, [page]); // Fetch employees whenever page changes

  // Fetch employees from the server
  const fetchEmployees = () => {
    fetch(`http://localhost:8000/users/?page=${page}&per_page=${perPage}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then(data => {
        setEmployees(data.results);
        setTotalPages(data.total_pages);
        setError(null); // Clear any previous errors
      })
      .catch(error => {
        console.error('Error fetching employees:', error);
        setError('Failed to fetch data');

        setTimeout(() => {
          setError(null);
        }, 5000);
      });
  };

  // Handle selection of employees for deletion
  const handleSelect = (id) => {
    if (selectedEmployees.includes(id)) {
      setSelectedEmployees(selectedEmployees.filter(empId => empId !== id));
    } else {
      setSelectedEmployees([...selectedEmployees, id]);
    }
  };

  // Handle opening edit modal for an employee
  const handleEdit = (employee) => {
    setCurrentEmployee(employee);
    setModalType('edit');
  };

  // Handle opening delete confirmation modal for an employee
  const handleDelete = (employee) => {
    setCurrentEmployee(employee);
    setDeleteModalOpen(true);
  };

  // Close modals and reset states
  const handleModalClose = () => {
    setModalType('');
    setCurrentEmployee(null);
    setDeleteModalOpen(false);
  };

  const handleSaveEdit = async () => {
    try {
      const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]')?.value;

      // Extract the updated fields from currentEmployee state
      const { id, name, username, password } = currentEmployee;

      const response = await fetch(`http://localhost:8000/users/edit/${id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken || '', // Ensure CSRF token is passed or empty string
        },
        body: JSON.stringify({ new_name: name, new_username: username, new_password: password }),
      });

      if (!response.ok) {
        throw new Error('Failed to save edits');
      }

      const updatedEmployee = await response.json();
      const updatedEmployees = employees.map(emp =>
        emp.id === updatedEmployee.id ? updatedEmployee : emp
      );
      setEmployees(updatedEmployees);
      setModalType(''); // Close the modal
      setCurrentEmployee(null); // Clear current employee state
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error('Error saving edits:', error);
      setError('Failed to save edits');
    }
  };
  

  // Confirm deletion of an employee
  const handleConfirmDelete = async () => {
    try {
      const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
      const response = await fetch(`http://localhost:8000/users/delete/${currentEmployee.id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete employee');
      }

      // Update local state after successful delete
      setEmployees(employees.filter(emp => emp.id !== currentEmployee.id));
      setSelectedEmployees(selectedEmployees.filter(empId => empId !== currentEmployee.id));
      setCurrentEmployee(null);
      setDeleteModalOpen(false);
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error('Error deleting employee:', error);
      setError('Failed to delete employee');
    }
  };

  // Helper function to validate password
  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*()])(?=.*[a-zA-Z]).{8,}$/;
    return passwordRegex.test(password);
  };

  // Add a new employee
  const handleAddEmployee = async (newEmployeeData) => {
    try {
      const response = await fetch('http://localhost:8000/users/add/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newEmployeeData),
      });

      if (!response.ok) {
        throw new Error('Failed to add employee');
      }

      const addedEmployee = await response.json();

      // Update local state with new employee
      setEmployees([...employees, addedEmployee]);
      setModalType(''); // Close the modal
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error('Error adding employee:', error);
      setError('Failed to add employee');
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-6">
                <h2>Manage Users</h2>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                <button className="btn btn-success mr-2" onClick={() => setModalType('add')}>
                  <i className="material-icons">&#xE147;</i>
                  <span>Add New User</span>
                </button>
              </div>
            </div>
          </div>

          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Username</th>
                <th>Password</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {employees.map(employee => (
                <tr key={employee.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedEmployees.includes(employee.id)}
                      onChange={() => handleSelect(employee.id)}
                    />
                  </td>
                  <td>{employee.name}</td>
                  <td>{employee.username}</td>
                  <td>{employee.password}</td>
                  <td>
                    <button className="edit" onClick={() => handleEdit(employee)}>
                      <i className="material-icons edit-icon" title="Edit">&#xe254;</i>
                    </button>
                    <button className="delete" onClick={() => handleDelete(employee)}>
                      <i className="material-icons delete-icon" title="Delete">&#xE872;</i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="clearfix">
            <div className="hint-text">Showing <b>{employees.length}</b> out of <b>{employees.length}</b></div>
            <ul className="pagination justify-content-end">
              <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => setPage(page - 1)}>Previous</button>
              </li>
              {[...Array(totalPages).keys()].map(num => (
                <li key={num} className={`page-item ${num + 1 === page ? 'active' : ''}`}>
                  <button className="page-link" onClick={() => setPage(num + 1)}>{num + 1}</button>
                </li>
              ))}
              <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => setPage(page + 1)}>Next</button>
              </li>
            </ul>
          </div>

          {/* Add Employee Modal */}
          {modalType === 'add' && (
            <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} role="dialog">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add Employee</h5>
                    <button type="button" className="close" onClick={handleModalClose}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={(e) => {
                      e.preventDefault();
                      const formData = new FormData(e.target);
                      const newEmployeeData = {
                        name: formData.get('name'),
                        username: formData.get('username'),
                        password: formData.get('password'),
                      };

                      // Client-side validation for name and username length
                      if (newEmployeeData.name.length > 10) {
                        setError('Name must be less than 10 characters');
                        return;
                      }
                      if (newEmployeeData.username.length > 10) {
                        setError('Username must be less than 10 characters');
                        return;
                      }

                      // Client-side validation for password
                      const password = newEmployeeData.password;
                      if (!validatePassword(password)) {
                        setError('Password must be at least 8 characters long and contain at least one special character and one numeric digit');
                        return;
                      }

                      // If all validations pass, proceed with adding the employee
                      handleAddEmployee(newEmployeeData);
                    }}>
                      <div className="form-group">
                        <label>Name</label>
                        <input type="text" name="name" className="form-control" maxLength="10" required />
                      </div>
                      <div className="form-group">
                        <label>Username</label>
                        <input type="text" name="username" className="form-control" maxLength="10" required />
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input type="password" name="password" className="form-control" minLength="8" required />
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleModalClose}>Cancel</button>
                        <button type="submit" className="btn btn-success">Add</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Edit Employee Modal */}
          {modalType === 'edit' && currentEmployee && (
            <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} role="dialog">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Edit Employee</h5>
                    <button type="button" className="close" onClick={handleModalClose}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <label>Name</label>
                      <input type="text" className="form-control" defaultValue={currentEmployee.name} maxLength="10" required onChange={(e) => setCurrentEmployee({ ...currentEmployee, name: e.target.value })} />
                    </div>
                    <div className="form-group">
                      <label>Username</label>
                      <input type="text" className="form-control" defaultValue={currentEmployee.username} maxLength="10" required onChange={(e) => setCurrentEmployee({ ...currentEmployee, username: e.target.value })} />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input type="password" className="form-control" defaultValue={currentEmployee.password} minLength="8" required onChange={(e) => setCurrentEmployee({ ...currentEmployee, password: e.target.value })} />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={handleModalClose}>Cancel</button>
                    <button type="button" className="btn btn-success" onClick={handleSaveEdit}>Save</button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Delete Employee Modal */}
          {deleteModalOpen && currentEmployee && (
            <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} role="dialog">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Delete Employee</h5>
                    <button type="button" className="close" onClick={handleModalClose}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure you want to delete this record?</p>
                    <p className="text-warning"><small>This action cannot be undone.</small></p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={handleModalClose}>Cancel</button>
                    <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Delete</button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Error Message */}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default EmployeeDashboard;
