import React, { useState, useEffect } from 'react';
import './Conversation.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import axios from 'axios';
import Bot from '../../images/logo.png';
import Male from '../../images/avatars/avatar1.png';
import Female from '../../images/avatars/avatar4.png';
import Male1 from '../../images/avatars/avatar2.png';
import Male2 from '../../images/avatars/avatar3.png';

const avatarsArray = [Male, Female, Male1, Male2];

const Chat = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentChat, setCurrentChat] = useState('');
  const [chats, setChats] = useState({});
  const [avatars, setAvatars] = useState({});

  useEffect(() => {
    // Fetch customer list from API
    axios.get('http://localhost:8000/customer-list/')
      .then(response => {
        const customerData = response.data;
        const initialChats = {};
        const initialAvatars = {};

        // Initialize chats object with customer names and dates
        customerData.forEach((customer) => {
          const name = customer.customer_name;
          initialChats[customer.id] = {
            id: customer.id,
            name: name,
            date: customer.date,
            messages: [], // Initialize messages array
            fallbackOccurred: customer.fallback,
          };

          // Assign a random avatar to each customer
          initialAvatars[customer.id] = avatarsArray[Math.floor(Math.random() * avatarsArray.length)];
        });

        setChats(initialChats);
        setAvatars(initialAvatars);
        // Set the first customer as the default current chat
        setCurrentChat(Object.keys(initialChats)[0] || '');
      })
      .catch(error => {
        console.error('Error fetching customer list:', error);
      });
  }, []);

  useEffect(() => {
    if (currentChat) {
      // Fetch chat messages for the current chat
      axios.get(`http://localhost:8000/conversations/?current_chat=${currentChat}`)
        .then(response => {
          // Find the key dynamically for the currentChat
          const chatKey = Object.keys(response.data).find(key => key !== 'lastMessageTime' && key !== 'fallbackOccurred');
          if (chatKey) {
            const chatData = response.data[chatKey];
            if (chatData && chatData.messages) {
              // Update chats state to include messages correctly
              setChats(prevChats => ({
                ...prevChats,
                [currentChat]: {
                  ...prevChats[currentChat],
                  messages: chatData.messages,
                  lastMessageTime: response.data.lastMessageTime,
                  fallbackOccurred: response.data.fallbackOccurred,
                },
              }));
            }
          }
        })
        .catch(error => {
          console.error(`Error fetching messages for ${currentChat}:`, error);
        });
    }
  }, [currentChat]);

  const contacts = Object.keys(chats).filter(contact =>
    chats[contact].name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleContactClick = (contact) => {
    setCurrentChat(contact);
  };

  const getAvatar = (contact) => {
    return avatars[contact] || Male;
  };

  const getFallbackIconClass = (contact) => {
    const chatData = chats[contact];
    if (chatData && chatData.fallbackOccurred) {
      return 'fallback-icon'; // Example class for fallback occurred
    }
    return '';
  };

  const formatTime = (time) => {
    return time.replace('T', ' ').replace('Z', '');
  };

  const truncateName = (name) => {
    return name.length <= 8 ? name : `${name.slice(0, 6)}..`;
  };

  return (
    <div className='summa h-100'>
      <div className="container-fluid h-100">
        <div className="row justify-content-center h-100">
          <div className="col-md-5 col-xl-4 chat-list" style={{ height: '110%' }}>
            <div className="card mb-sm-3 mb-md-0 contacts_card">
              <div className="card-header">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Search..."
                    name=""
                    className="form-control search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-prepend">
                    <span className="input-group-text search_btn">
                      <i className="fas fa-search"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-body contacts_body">
                <ul className="contacts">
                  {contacts.map(contact => (
                    <li
                      key={contact}
                      className={`d-flex align-items-center ${currentChat === contact ? 'active' : ''}`}
                      onClick={() => handleContactClick(contact)}
                    >
                      <div className="d-flex bd-highlight">
                        <div className="img_cont">
                          <img
                            src={getAvatar(contact)}
                            className="rounded-circle user_img"
                            alt="user"
                          />
                          <span className={`${getFallbackIconClass(contact)}`}></span>
                        </div>
                        <div className="user_info">
                          <span>{chats[contact].name}</span>
                          <p>{chats[contact].date}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="card-footer"></div>
            </div>
          </div>
          <div className="col-md-7 col-xl-8 chat-box" style={{ height: '110%' }}>
            <div className="card convo">
              <div className="card-header msg_head">
                <div className="d-flex bd-highlight">
                  <div className="img_cont">
                    <img
                      src={getAvatar(currentChat)}
                      className="rounded-circle user_img"
                      alt="user"
                    />
                    <span className={`${getFallbackIconClass(currentChat)}`}></span>
                  </div>
                  <div className="user_info convo_head">
                    <span>{truncateName(chats[currentChat]?.name || '')}</span>
                    <p>{chats[currentChat]?.messages.length} Messages</p>
                  </div>
                  <h6 className='Type'>
                    {chats[currentChat] ? (
                      chats[currentChat].fallbackOccurred ? 'Human Agent' : 'Bot Fulfilled'
                    ) : (
                      'Loading...'
                    )}
                  </h6>
                </div>
              </div>
              <div className="card-body msg_card_body">
                {chats[currentChat]?.messages.map((message, index) => (
                  <div
                    key={index}
                    className={`d-flex justify-content-${message.type === 'sent' ? 'end' : 'start'} mb-4`}
                  >
                    {message.type === 'received' && (
                      <div className="img_cont_msg">
                        <img
                          src={getAvatar(currentChat)}
                          className="rounded-circle user_img_msg"
                          alt="user"
                        />
                      </div>
                    )}
                    <div className={message.type === 'sent' ? 'msg_cotainer_send' : 'msg_cotainer'}>
                      {message.text}
                      <span className={message.type === 'sent' ? 'msg_time_send' : 'msg_time'}>
                        {formatTime(message.time)} {/* Removing 'T' and 'Z' */}
                      </span>
                    </div>
                    {message.type === 'sent' && (
                      <div className="img_cont_msg">
                        <img
                          src={Bot}
                          className="rounded-circle user_img_msg"
                          alt="user"
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="card-footer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
