// DataComponent.jsx

import React, { useState, useEffect } from 'react';
import {
  UilStar,
  UilUser,
  UilClock,
  UilEstate,
  UilClipboardAlt,
  UilPackage
} from "@iconscout/react-unicons";
import img1 from "../images/avatars/avatar1.png";
import img2 from "../images/avatars/avatar2.png";
import img3 from "../images/avatars/avatar4.png";

// Sidebar Data
export const SidebarData = [
  {
    icon: UilEstate,
    heading: "Dashboard",
  },
  {
    icon: UilClipboardAlt,
    heading: "Conversations",
  },
  {
    icon: UilPackage,
    heading: 'Users'
  },
];

export const UpdatesData = [
  {
    img: img1,
    name: "Andrew Thomas",
    noti: "enquired about the budget of services.",
    time: "16 Jun 2024",
  },
  {
    img: img2,
    name: "James Bond",
    noti: "enquired about job opportunities.",
    time: "17 Jun 2024",
  },
  {
    img: img3,
    name: "Iron Man",
    noti: "enquired about the working hours.",
    time: "16 Jun 2024",
  },
];

const DataComponent = () => {
  const [cardsData, setCardsData] = useState([
    {
      title: "Ratings",
      color: {
        backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 70%)",
        boxShadow: "0px 10px 20px 0px #e0c6f5",
      },
      barValue: 0,
      info: "Above 3.5 stars",
      value: "0/0",
      png: UilStar,
      series: [
        {
          name: "Sales",
         
        },
      ],
    },
    {
      title: "Fallback",
      color: {
        backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
        boxShadow: "0px 10px 20px 0px #FDC0C7",
      },
      barValue: 0,
      info: "Last 6 months",
      value: "0/0",
      png: UilUser,
      series: [
        {
          name: "Today's Views",
         
        },
      ],
    },
    {
      title: "Chats",
      color: {
        backGround:
          "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
        boxShadow: "0px 10px 20px 0px #F9D59B",
      },
      barValue: 0,
      info: "Last 4 weeks",
      value: "0/0",
      png: UilClock,
      series: [
        {
          name: "Expenses",
         
        },
      ],
    },
  ]);

  useEffect(() => {
    fetch('http://localhost:8000/dashboard/')
      .then(response => response.json())
      .then(data => {
        const { count_above_3, total_documents ,total_fallback_last_6_months} = data;
        const barValue = Math.round((count_above_3 / total_documents) * 100);
        const value = `${count_above_3}/${total_documents}`;
        const fallback = `${total_fallback_last_6_months}/${total_documents}`;
        const fall_percentage=Math.round((total_fallback_last_6_months/total_documents)*100);
        const updatedCardsData = [
          {
            title: "Ratings",
            color: {
              backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 70%)",
              boxShadow: "0px 10px 20px 0px #e0c6f5",
            },
            barValue: barValue,
            info: "Above 3.5 stars",
            value: value,
            png: UilStar,
            series: [
              {
                name: "Sales",
                data: [31, 40, 28, 51, 42, 109, 100],
              },
            ],
          },
          {
            title: "Fallback",
            color: {
              backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
              boxShadow: "0px 10px 20px 0px #FDC0C7",
            },
            barValue: fall_percentage,
            info: "Last 6 months",
            value: fallback,
            png: UilUser,
            series: [
              {
                name: "Today's Views",
                
              },
            ],
          },
          {
            title: "Chats",
            color: {
              backGround:
                "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
              boxShadow: "0px 10px 20px 0px #F9D59B",
            },
            barValue: 80,
            info: "Last 4 weeks",
            value: total_documents,
            png: UilClock,
            series: [
              {
                name: "Expenses",

              },
            ],
          },
        ];

        setCardsData(updatedCardsData);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return cardsData; // Return the cardsData for use in other components
};

export default DataComponent;
