import React from "react";
import { useState } from "react"; 
import "./Navbar.css";
import Logo from "../../images/logo.png";
import { UilSignOutAlt } from "@iconscout/react-unicons";
import { SidebarData } from "../../Data/Data.js";
import { UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";


const Sidebar = ({ selected, setSelected }) => {
  const [expanded, setExpaned] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming user is initially logged in
  const [showLogin, setShowLogin] = useState(false);
 
 

  const sidebarVariants = {
    true: {
      left: '0'
    },
    false: {
      left: '-60%'
    }
  };
  const handleLogout = () => {
    // Simulate logout action
    // Redirect to login page (assuming your app's root is index.html)
    window.location.reload();
  };
 

  return (
    
      <>
      <div className="bars" style={expanded ? { left: '60%' } : { left: '5%' }} onClick={() => setExpaned(!expanded)}>
        <UilBars />
      </div>
      <motion.div className='sidebar'
        variants={sidebarVariants}
        animate={window.innerWidth <= 768 ? `${expanded}` : ''}
      >
        {/* logo */}
        <div className="logo">
          <img src={Logo} alt="logo" />
          <span></span>
        </div>

        <div className="menu">
        
          {SidebarData.map((item, index) => {
            return (
              <div
                className={selected === index ? "menuItem active" : "menuItem"}
                key={index}
                onClick={() => setSelected(index)}
              >
                <item.icon />
                <span>{item.heading}</span>
              </div>
            );
          })}
          {/* signoutIcon */}
          <div className="menuItem logout" onClick={handleLogout}>
            <UilSignOutAlt />
          </div>
          
        </div>
      </motion.div>
    
    </>
  );
};

export default Sidebar;
