import React from "react";
import CustomerReview from "./Bargraph/Bargraph";
import Updates from "./FallbackEvents/FallbackEvents";
import "./RightSide.css";

const RightSide = ({ selectedCard }) => {
  // Define a mapping of headings based on the selected card
  const headings = {
    0: "Customer Ratings",
    1: "Fallbacks ",
    2: "Chats Counts",
  };

  return (
    <div className="RightSide">
      <div className="grid-container">
        <div className="grid-item">
          <h3>Fallback Events</h3>
          <Updates />
        </div>
        <div className="grid-item">
          <h3>{headings[selectedCard]}</h3> {/* Use the selected heading */}
          <CustomerReview selectedCard={selectedCard} />
        </div>
      </div>
    </div>
  );
};

export default RightSide;
