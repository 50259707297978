import React, { useState } from 'react';
import './Layout.css';
import MainDash from '../Routes/Routes';
import RightSide from '../sections/RightSide/RightSide';
import Sidebar from '../sections/Navbar/Navbar';
import Header from '../sections/Header/Header';

function Dashboard() {
  const [selected, setSelected] = useState(0); // For sidebar navigation
  const [selectedCard, setSelectedCard] = useState(0); // For card selection

  return (
    <div className="App">
      <div className="AppGlass">
        <Sidebar selected={selected} setSelected={setSelected} />
        <div className="center-column">
          <Header selected={selected} />
          <MainDash selected={selected} onCardSelect={setSelectedCard} />
        </div>
        <RightSide selectedCard={selectedCard} />
      </div>
    </div>
  );
}

export default Dashboard;
