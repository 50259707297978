// Cards.jsx

import React from "react";
import "./Cards.css";
import Card from "./Card/Card";
import DataComponent from "../../../Data/Data"; // Import DataComponent

const Cards = ({ onCardSelect }) => {
  const cardsData = DataComponent(); // Fetch data from DataComponent

  if (!cardsData || cardsData.length === 0) {
    return <div>Loading...</div>; // Show loading if data is not yet fetched
  }

  return (
    <div className="Cards">
      {cardsData.map((card, id) => (
        <div className="parentContainer" key={id} onClick={() => onCardSelect && onCardSelect(id)}>
          <Card
            title={card.title}
            color={card.color}
            barValue={card.barValue}
            info={card.info}
            value={card.value}
            png={card.png}
            series={card.series}
          />
        </div>
      ))}
    </div>
  );
};

export default Cards;
