import React, { useState } from 'react';
import './Login.css'; // Import your CSS file
import admin from '../../images/login.svg';
import logo from '../../images/logo.png';
import axios from 'axios';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Basic validation for demo purpose
    if (email.trim() === '' || password.trim() === '') {
      alert('Please enter email and password.');
      return;
    }

    try {
      // Make API call to Django backend
      const response = await axios.post('http://localhost:8000/api/login/', {
        email,
        password,
      });

      // Assuming the backend returns a success message upon successful login
      if (response.data.message === 'Login successful') {
        // Call the onLogin function passed from App.js to handle successful login
        onLogin(email);
      } else {
        alert('Invalid email or password');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      alert('Error logging in. Please try again.');
    }
  };

  return (
    <div className="page">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="specific-logo" />
      </div>
      <div className="container">
        <div id="glass" className="glass1">
          <div className="wrapper">
            <div className="title">Welcome Back!!</div>
            <form className="form" onSubmit={handleSubmit}>
              <div className="field">
                <label>Email Address: </label>
                <input
                  type="text"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Enter your ID"
                />
              </div>
              <div className="field">
                <label>Password: </label>
                <input
                  type="password"
                  name="pass"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  placeholder="Enter a Password"
                />
              </div>
              <button id="btn" className="center" type="submit">
                Log In
              </button>
            </form>
          </div>
        </div>
        <div className="image-section">
          <img id="f-img" src={admin} alt="PayrollSystem" />
        </div>
      </div>
    </div>
  );
};

export default Login;
